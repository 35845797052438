










































import Vue from "vue";
import Component from "vue-class-component";
import { format } from "date-fns";

@Component({})
export default class FeedbackList extends Vue {
  pageNumber = 1;
  pageSize = 100;
  isLoading = false;
  dataList = [];

  getCreateTime(timestamp: number): string {
    return format(new Date(timestamp), "yyyy-MM-dd HH:MM:SS");
  }

  mounted() {
    this.getAllExceptionInfo();
  }

  getAllExceptionInfo() {
    const _this = this;
    _this.$axios({
      url: "/api/v1/feedback/query",
      method: "post",
      data: {
        pageNumber: _this.pageNumber,
        pageSize: _this.pageSize,
      },
    })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.dataList = response.data.data.list;
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  deleteExceptionInfo(id: number) {
    const _this = this;
    _this.isLoading = true;
    _this.$axios({
      url: "/api/v1/feedback/delete",
      method: "post",
      data: {
        id: id,
      },
    })
      .then(function (response) {
        _this.isLoading = false;
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.getAllExceptionInfo();
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }
}
